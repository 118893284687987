<div class="container-fluid-lg" *ngIf="relatedproducts.length > 0">
  <div class="row bg-white rounded-4">
    <div class="p-4 border-bottom border-2">
      <app-title [title]="title" [class]="''"></app-title>
    </div>
    <!-- <div class="row g-sm-3 g-2" style="border-top: 1px solid var(--theme-color);">
    <div
      class="col-xxl-2 col-lg-3 col-md-4 col-6 product-box-contain"
      *ngFor="let product of relatedproducts"
    >
      <app-product-box
        [product]="product"
        [class]="'product-box-3'"
        [style]="'horizontal'"
      ></app-product-box>
    </div>
  </div> -->
    <div class="product-section p-4">
      <div class="product-left-box">
        <div class="product-main-1 no-arrow">
          <owl-carousel-o
            [options]="productSimilartSlider"
            id="owlCar"
            #owlCar
            class="product-slide"
          >
            <ng-container *ngIf="relatedproducts.length > 0" id="related">
              <ng-container *ngFor="let product of relatedproducts; index as i">
                <ng-template carouselSlide [id]="product.productID.toString()">
                  <div class="p-1 d-flex w-100">
                    <div class="slider-image w-100">
                      <app-product-box
                        [product]="product"
                        [class]="'product-box-3'"
                        [style]="'horizontal'"
                      ></app-product-box>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, Inject, Input, Optional, PLATFORM_ID, TransferState, ViewChild, afterNextRender } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProductService } from '@/app/shared/services/product.service';
import { CommonService } from '@/app/shared/services/common.service';

import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import * as data from '@/app/shared/data/owl-carousel';
import { Option } from '@/app/shared/interface/common.interface';
import { DosageForm, Product, ProductInfoType, ProductModel } from '@/app/shared/interface/product.interface';
import { SlidesOutputData } from 'ngx-owl-carousel-o';
import { AccountService } from '@/app/shared/services/account.service';
import { firstValueFrom } from 'rxjs';
import { SeoService } from '@/app/shared/services/seo.service';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { REQUEST } from '@/express.tokens';
import { AuthService, IS_AUTHENTICATED } from '@/app/shared/services/auth.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.scss'
})
export class ProductDetailComponent {

  @Input() option: Option | null;

  @ViewChild('productDetailImageModal') productDetailImageModal: any;

  public breadcrumb: Breadcrumb = {
    title: "Sản Phẩm",
    items: [{ label: 'Sản Phẩm', active: true }]
  }

  productId: any;
  productSlug: any;
  public product: Product ;
  public productMainThumbSlider = data.productMainThumbSlider;
  public productThumbSlider = data.productThumbSlider;
  public activeSlide: string = '0';
  public isAuthenticated: boolean = false;

  public views: number = 1524;
  public isAccountActivated: boolean;

  productSuggestionIds: number[] = [];

  isBrowser: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    public commonService: CommonService,
    private accountService: AccountService,
    private seoService: SeoService,
    private cookieService: SsrCookieService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isAuthenticated = this.authService.isAuthenticated();
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener("resize", () => {
        this.changeItemsPerSlide();
      })

      this.isBrowser = true;
    };
  }


  ngOnInit() {
    // const localStorage = this.document.defaultView?.localStorage;
    // const token = this.cookieService.get('token');
    // const userName = this.cookieService.get('username');
    // let jwtToken = this.commonService.parseJwt(token!);
    // this.isAuthenticated = (jwtToken && jwtToken.UserName == userName!) ? true : false;
    // if (this.isAuthenticated) {
    //   this.isAccountActivated = this.accountService.checkAccountActivated();
    // }

    this.productId = this.route.snapshot.paramMap.get('id');
    this.getProductDetailBySlug();
  }

  changeItemsPerSlide() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 756) {
        this.productThumbSlider.responsive![0].items = 2;
      }
      else {
        this.productThumbSlider.responsive![0].items = 6;
      }
    }
  }

  getProductDetail() {

    this.productService.getProductById(this.productId).subscribe({
      next: (res: Product) => {
        if (res) {
          this.product = res;
          this.setProductDetail();
        }
      },
      error: (error) => {

        // console.log(error);
      }
    })
  }

  getProductDetailBySlug() {
    this.route.data.subscribe({
      // this.productService.getProductBySlug(this.productId).subscribe({
        next: (res: any) => {
          if (res && res.product) {
            this.product = res.product;
  
            this.setProductDetail();
          }
          else {
            this.getProductDetail();
          }
        },
        error: (error) => {
          this.getProductDetail();
          console.log(error);
        }
      });
  }

  setProductDetail() {
    this.breadcrumb.items = [];
    const categories = this.product?.categories.flatMap(c => c.name).join("-");
    this.breadcrumb.items.push({ label: categories, url: categories, active: false });
    this.breadcrumb.items.push({ label: this.product.name, active: true });
    this.commonService.setProductDetail(this.product);
    for (let info of this.product.productInfo) {
      if (info.type == ProductInfoType.Dosage) {
        info.value = this.commonService.getDosageForm(Number(info.value) as DosageForm);
      }
    }

    if (this.product.imageUrls.length == 1) {
      this.productMainThumbSlider.mouseDrag = false;
      this.productMainThumbSlider.touchDrag = false;
      this.productMainThumbSlider.pullDrag = false;
    }
    else {
      this.productMainThumbSlider.mouseDrag = true;
      this.productMainThumbSlider.touchDrag = true;
      this.productMainThumbSlider.pullDrag = true;
    }

    this.productSuggestionIds = this.product.productSuggestions?.map(p => p.productId);

    const metaTags = {
      title: this.product.name,
      metaTitle: this.product.metaTitle ? this.product.metaTitle : this.product.name,
      description: this.product.metaDescription ? this.product.metaDescription : this.product.name ,
      image: this.product.metaImages.length > 0 ? this.product.metaImages[0] : this.product.imageUrls[0]
    };
    this.seoService.generateTags(metaTags);
  }

  getPassedData(data: SlidesOutputData) {
    this.activeSlide = data.startPosition!.toString();
  }

  openModal(index: number, productDetailImageModal: any) {
    this.activeSlide = index.toString();
    productDetailImageModal.openModal();
  }

  setSlide(value: any, owlCar: any) {
    this.activeSlide = value;
    owlCar.to(this.activeSlide);
  }

  ngOnDestroy() {
    this.seoService.generateTags({});
  }
}

import { Component, Inject, PLATFORM_ID, TransferState } from '@angular/core';

import { ProductService } from '@/app/shared/services/product.service';
import { CommonService } from '@/app/shared/services/common.service';

import { Breadcrumb } from '@/app/shared/interface/breadcrumb';
import {
  PaginationModel,
  Product,
} from '@/app/shared/interface/product.interface';
import { ActivatedRoute } from '@angular/router';
import { QueryStringService } from '@/app/shared/services/querystring.service';
import { Subscription, firstValueFrom } from 'rxjs';
import {
  CatetoryQueryString,
  LabelQueryString,
} from '@/app/shared/filter';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AuthService, TOKEN } from '@/app/shared/services/auth.service';
import { Token } from '@angular/compiler';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss',
})
export class ProductComponent {
  private changeDataSubcription?: Subscription;

  public breadcrumb: Breadcrumb = {
    title: 'Sản Phẩm',
    items: [{ label: 'Sản Phẩm', active: false }],
  };

  public gridCol: string;
  public skeletonLoader: boolean = true;
  public skeletonItems = Array.from({ length: 40 }, (_, index) => index);

  public gridClass: string =
    'row g-sm-4 g-3 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section';

  public filter: any = {
    page: 1, // Current page number
    status: 1,
    field: '',
    price: '',
    category: '',
    tag: '',
    sort: '', // ASC, DSC
    sortBy: '',
    rating: '',
    attribute: '',
  };

  public products: Product[] = [];
  public productsExtend: Product[] = [];
  // public pagination: Paginate;

  _currentPage: number = 1;
  _paginateRange: number = 7;
  public bodyExtract = {
    limit: 50,
    searchTerm: '',
  };
  bodyProduct = {
    limit: 50,
    offset: 0,
  };

  public offCanvasMenu: boolean = false;
  private routerEvent: Subscription;
  private labelQueryString: LabelQueryString;
  public isAuthenticated: boolean = false;

  isListView: boolean = false;

  constructor(
    private productService: ProductService,
    private commonService: CommonService,
    private queryStringService: QueryStringService,
    private router: Router,
    private transferState: TransferState,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isAuthenticated = this.authService.isAuthenticated();

      this.changeDataSubcription =
        this.queryStringService.eventChangeQueryString.subscribe(async () => {
          await this.getExtractProduct();
          this.routerEvent = this.router.events.subscribe({
            next: (val: any) => {
              if (val instanceof NavigationEnd) {
                if (this.router.url.includes('/product')) {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }
              }
            },
          });
        });

      if (
        this.queryStringService.queryString.some(
          (f) => f instanceof CatetoryQueryString
        )
      ) {
        if (this.commonService.productCategories?.length) {
          this.queryStringService.startFetchingData();
        } else {
          let sub = this.commonService.getCategories.subscribe((data) => {
            if (data) {
              this.queryStringService.startFetchingData();
              sub?.unsubscribe();
            }
          });
        }
      } else {
        this.queryStringService.startFetchingData();
      }
    }
  }

  async getExtractProduct() {
    let body = this.queryStringService.buildFetchingModel(
      this.bodyProduct.limit
    );
    this.skeletonLoader = true;
    body.searchTerm = body.searchTerm!;

    this.products = [];
    this.productsExtend = [];
    const token = this.transferState.get(TOKEN, '');
    if (this.bodyExtract.searchTerm && token) {
      const res = await firstValueFrom(
        this.productService.searchProductsForMember(JSON.stringify(this.bodyExtract))
      );
      if (res) {
          this.products = res.products;
          if (this.products.length < 5) {
            await this.getProducts();
            this.productsExtend = this.productsExtend.filter(
              (item) => !this.products.some((i) => i.id === item.id)
            );
          }
          let pagination: PaginationModel = {
            limit: body.limit,
            offset: body.offset,
            searchTerm: body.searchTerm,
            totalItems: res.total,
            sortBy: body.sortBy,
            category: '',
          };
          this.queryStringService.changePagination(pagination);
          this.skeletonLoader = false;

        }

        this.skeletonLoader = false;
    } else {
      await this.getProducts();
      this.productsExtend = this.productsExtend.filter(
        (item) => !this.products.some((i) => i.id === item.id)
      );
      this.skeletonLoader = false;
    }
  }

  async getProducts(pageNumber?: number) {
    if (pageNumber == undefined) {
    }

    let body = this.queryStringService.buildFetchingModel(
      this.bodyProduct.limit
    );

    this.skeletonLoader = true;
    this.productsExtend = [];

    const token = this.transferState.get(TOKEN, '');

    if (token) {
      const res = await firstValueFrom(
        this.productService.getProductsPaginationForMember(body)
      );

      if (res) {
        this.skeletonLoader = false;
        if (res.products) {
          this.productsExtend = res.products;
          let pagination: PaginationModel = {
            limit: body.limit,
            offset: body.offset,
            searchTerm: body.searchTerm,
            totalItems: res.total,
            sortBy: body.sortBy,
            category: '',
          };
          this.queryStringService.changePagination(pagination);
        }

        this.skeletonLoader = false;
      }
    }
    else {
      const res = await firstValueFrom(
        this.productService.getProductsPaginationforGuest(body)
      );
      if (res) {
        this.skeletonLoader = false;
        if (res.products) {
          this.productsExtend = res.products;
          let pagination: PaginationModel = {
            limit: body.limit,
            offset: body.offset,
            searchTerm: body.searchTerm,
            totalItems: res.total,
            sortBy: body.sortBy,
            category: '',
          };
          console.log(pagination)
          this.queryStringService.changePagination(pagination);
        }

        this.skeletonLoader = false;
      }
    }


  }

  queryStringBuilder(event: any) {
    this.labelQueryString = new LabelQueryString(event);
    this.queryStringService.changeQueryString(this.labelQueryString);
  }

  setGridClass(gridClass: any) {
    this.gridClass = gridClass.class;
    this.isListView = gridClass.isListView;
  }

  showSideBar(e: boolean) {
    this.offCanvasMenu = e;
  }

  ngOnDestroy() {
    this.changeDataSubcription?.unsubscribe();
    this.routerEvent?.unsubscribe();
  }

}

import { environment } from '@/environments/environment';
import { Injectable, TransferState } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject } from 'rxjs';
import { USERNAME } from './auth.service';
@Injectable({
    providedIn: 'root'
})
export class SignalrService {
    public data: any;
    public isConnected: boolean = false;
    private hubConnection: signalR.HubConnection;

    private paymentNotify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public getPaymentNotify = this.paymentNotify.asObservable();
    public setPaymentNotify(value: boolean) {
        this.paymentNotify.next(value);
    }

    constructor(
        private cookieService: SsrCookieService,
        private transferState: TransferState
    ) { }

    public startConnection() {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.baseURL}notificationHub`)
            .withAutomaticReconnect()
            .build();

        this.hubConnection
            .start()
            .then(() => {
                // console.log('Connection started');
                this.isConnected = true;
                this.hubConnection.invoke('Connect', this.transferState.get(USERNAME, ''))
                    .catch(err => console.error(err.toString()));
            })
            .catch(err => console.log('Error while starting connection: ' + err));

        this.hubConnection.on("Payment", (data: boolean) => this.PaymentSuccess(data));
    }

    public stopConnection() {
        this.hubConnection.invoke("Disconnect",this.transferState.get(USERNAME,'')).then(() => {
            // console.log('Connection stopped');
        });
    }

    public PaymentSuccess(event: boolean) {
        this.setPaymentNotify(event);
    }
}